import React from 'react'
import Layout from "../components/App/Layout"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import gallery1 from '../assets/images/gallery/gallery1.jpg'
import gallery2 from '../assets/images/gallery/gallery2.jpg'
import gallery3 from '../assets/images/gallery/gallery3.jpg'
import gallery4 from '../assets/images/gallery/gallery4.jpg'
import gallery5 from '../assets/images/gallery/gallery5.jpg'
import gallery6 from '../assets/images/gallery/gallery6.jpg'
import gallery7 from '../assets/images/gallery/gallery7.jpg'
import gallery8 from '../assets/images/gallery/gallery8.jpg'
import gallery9 from '../assets/images/gallery/gallery9.jpg'
import Lightbox from 'react-image-lightbox'
import ReactCompareImage from 'react-compare-image';

const images = [
    (gallery1),
    (gallery2),
    (gallery3),
    (gallery4),
    (gallery5),
    (gallery6),
    (gallery7),
    (gallery8),
    (gallery9),
];

const Compare = () => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);

    return (
        <Layout>
            <NavbarStaging />

            <div className="gallery-area pt-100 pb-70">
                <div className="container">
                    <div className="section-title">
                        <h2>Scandinavian</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <ReactCompareImage leftImage={gallery1} rightImage={gallery2} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <ReactCompareImage leftImage={gallery3} rightImage={gallery4} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <ReactCompareImage leftImage={gallery5} rightImage={gallery6} />
                            </div>
                        </div>
                    </div>
                    <div className="section-title">
                        <h2>Bedroom</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <ReactCompareImage leftImage={gallery1} rightImage={gallery2} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <ReactCompareImage leftImage={gallery3} rightImage={gallery4} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <ReactCompareImage leftImage={gallery5} rightImage={gallery6} />
                            </div>
                        </div>
                    </div>
                    <div className="section-title">
                        <h2>Bath Room</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <ReactCompareImage leftImage={gallery1} rightImage={gallery2} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <ReactCompareImage leftImage={gallery3} rightImage={gallery4} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <ReactCompareImage leftImage={gallery5} rightImage={gallery6} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Lightbox */}
                {isOpenImage && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpenImage(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
            
            <Footer />
        </Layout>
    );
}

export default Compare